<template>
    <div></div>
</template>

<script>
  // import { globalConfig, UnionPayJsdk } from '../../utils/mixin'

  export default {
    name: 'Test',
    // mixins: [globalConfig, UnionPayJsdk],
    methods: {
      // getUnionPayJsdkParam: function () {
      //   const that = this
      //   this.axios.post(this.apiUrl + 'mall/jsapi_parameter/getUnionPayUpsdkParameter', {
      //     url: 'https://club.seetop.net.cn/'
      //   }).then((response) => {
      //     console.log(this)
      //     // this.$dialog.alert({
      //     //   message: '6'
      //     // })
      //     console.log('5555555555')
      //     const data = response.data
      //     if (data.status === 1) {
      //       that.UnionPayJsdkConfig(data.info.appId, data.info.timestamp, data.info.nonceStr, data.info.signature)
      //     } else {
      //       that.$dialog.alert({
      //         title: '提示',
      //         message: '服务繁忙'
      //       })
      //     }
      //   })
      // }
    },
    mounted () {
      // this.getUnionPayJsdkParam()
    }
  }
</script>

<style scoped>

</style>
